@import url('https://fonts.googleapis.com/css2?family=Alexandria:wght@100;300;600&display=swap');

* {
  margin: 0;
  padding: 0;
  font-family: 'Alexandria', sans-serif;
}
.App {
  font-family: sans-serif;
  min-height: 100vh;
}

body {
  background-color: black;
}

body,
html {
  overflow: hidden;
  height: 100%;
  overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}
.main {
  width: 100%;
  height: 100vh;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content {
  position: absolute;
  width: 100%;
  top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: baseline;
  color: white;
  left: 1rem;
}

.content p {
  margin-top: -4px;
  font-size: 8px;
}

@media screen and (max-width: 576px) {
  .content {
    font-size: xx-small;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.videowrapper {
  float: none;
  clear: both;
  width: 100%;

  padding-bottom: 56.2%;
  height: 0;

  /* Put the video in the background */
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
}
.videowrapper iframe {
  position: absolute;
  top: 5rem;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.second {
  padding-top: 4rem;
  background-color: black;
  position: relative;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  color: white;
  padding-bottom: 2rem;
}

.second a {
  margin-top: 10rem;
  font-size: 0.75rem;
  color: inherit;
  text-decoration: none;
}

.address {
  font-size: 0.5rem;
  font-weight: 100;
}

.contact-info {
  margin: 2rem 2rem;
  display: inline-block;
  align-items: center;
  justify-content: center;
}

.footer {
  color: white-50;

  bottom: 0;
}

.office {
  font-size: 12px;
  margin-bottom: 3px;
  text-align: center;
}

/* footer {
  background-color: black;
  color: white;
  text-align: center;
  font-size: 0.75rem;
  font-weight: 100;
  padding-bottom: 0.5rem;
  bottom: 0px;
} */

.icon {
  vertical-align: middle;
}

.phone-numbers {
  width: max-content;
  display: inline-block;
  text-align: start;
}

footer {
  position: relative;
  font-size: smaller;
  background-color: transparent;
  bottom: 0;
  left: 0;
  right: 0;
  color: grey;
  text-align: center;
  /* padding-bottom: 3px; */
}
